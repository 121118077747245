import React from 'react'
import toReact from 'svelte-adapter/react'
import Button from '@santiment-network/ui/Button'
import PaymentDialog from '../PaymentDialog/PaymentDialog'
import ChangePlanDialog from '../ChangePlanDialog/ChangePlanDialog'
import PipedriveDialogBtn from '../Pipedrive/Pipedrive'
import { tr, trStr } from '../../utils/translate'
import styles from './index.module.scss'
import PlanButtonSvelte from './PlanButton.svelte'

const PlanButton = toReact(PlanButtonSvelte, {}, 'div')

const PlanActionDialog = props =>
  props.subscription ? (
    <ChangePlanDialog {...props} />
  ) : (
    <PaymentDialog {...props} />
  )

export default {
  BUSINESS_PRO: {
    Component: PlanButton,
    link: 'Get Started Now',
    features: [
      '2 years of historical data through API',
      'Real-time API data without restrictions',
      intl => `600K ${trStr(intl, 'plan.feature.AC.month')}`,
      '50K credits for Queries tool',
      'Dedicated account manager',
      'Technical support included',
    ],
  },
  BUSINESS_MAX: {
    link: 'Get Started Now',
    isPopular: true,
    Component: PlanButton,
    features: [
      'Full historical data and metrics',
      'Real-time API data without restrictions',
      intl => `1200K ${trStr(intl, 'plan.feature.AC.month')}`,
      '500K credits for Queries tool',
      'Dedicated account manager',
      'Technical support included',
      'Multi-seat account with 3 seats',
    ],
  },
  CUSTOM: {
    discount: 'price.bill_discount.custom',
    link: 'cta.contact',
    Component: props => (
      <PipedriveDialogBtn
        {...props}
        title="Enterprise Plan Enquiry"
        src="https://calendly.com/santiment-team/santiment-enterprise-plan-enquiry"
      />
    ),
    features: [
      'Custom development of data solutions',
      'Data reselling rights agreement',
      'SLA',
      'All historical data available',
      'Custom API calls and Queries credits',
      'Technical support included',
    ],
  },

  // for deprecated plans support
  FREE: {
    discount: 'price.bill_discount.free',
    link: 'Upgrade now',
    Component: () => (
      <Button accent="blue" border fluid className={styles.link} disabled>
        {tr('cta.default_plan')}
      </Button>
    ),
    features: [
      'HDA',
      'metrics',
      intl => `100 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `500 ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `1K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  ESSENTIAL: {
    link: 'Get Started Now',
    Component: PlanActionDialog,
    features: [
      'HDA',
      'metrics',
      intl => `400 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `20k ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `300K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  PRO: {
    isPopular: true,
    Component: PlanActionDialog,
    link: 'Get Started Now',
    features: [
      'HDA',
      <span>
        {tr('plan.feature.metrics', 'Essential')} +
        <a className={styles.metricsLink} href="#faq-advanced">
          {tr('plan.feature.metrics.advanced', 'Advanced metrics*')}
        </a>
      </span>,
      intl => `600 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `30k ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `600K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  PREMIUM: {
    desc: '',
    Component: PlanActionDialog,
    link: 'Upgrade now',
    features: [
      'HDA',
      intl => (
        <>
          180 {trStr(intl, 'plan.feature.AC')}
          <br />
          500k {trStr(intl, 'plan.feature.AC.month')}
        </>
      ),
      <>
        {tr('plan.feature.AM')} <span className={styles.ast}>*</span>
      </>,
      tr('plan.feature.NA'),
    ],
  },
}
