import React from 'react'
export default props => (
  <svg width='14' height='14' {...props} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14zM8.68 3.59a3.2 3.2 0 0 0-.95-.32V2.5a.29.29 0 0 0-.08-.21.29.29 0 0 0-.21-.09h-.9a.29.29 0 0 0-.2.09.29.29 0 0 0-.1.2v.75c-.31.06-.61.16-.88.3A2.2 2.2 0 0 0 4.2 4.66c-.1.24-.16.5-.16.79 0 .33.04.61.13.85s.22.44.4.62c.18.18.4.33.68.45s.6.22.97.3c.4.11.76.2 1.06.26.31.07.57.14.77.21.22.07.37.16.48.26.1.1.15.24.15.4 0 .17-.05.31-.14.43-.1.11-.22.2-.38.29-.15.07-.33.12-.54.16a4.5 4.5 0 0 1-1.34-.02 1.95 1.95 0 0 1-.46-.19 1.02 1.02 0 0 1-.3-.25l-.18-.29a.5.5 0 0 0-.17-.2.52.52 0 0 0-.28-.06h-.77a.32.32 0 0 0-.22.09.24.24 0 0 0-.08.2c.01.28.09.54.22.78.13.22.3.43.52.6a3.35 3.35 0 0 0 1.69.7v.75c0 .08.03.15.09.21s.13.09.2.09h.9c.08 0 .15-.03.2-.09a.29.29 0 0 0 .1-.2v-.76a3.8 3.8 0 0 0 1.02-.27c.3-.13.55-.3.76-.5a1.97 1.97 0 0 0 .65-1.47c0-.3-.05-.57-.14-.8a1.44 1.44 0 0 0-.41-.62c-.2-.18-.44-.34-.75-.46a5.9 5.9 0 0 0-1.11-.34l-1.04-.23a5.02 5.02 0 0 1-.7-.21c-.17-.08-.3-.17-.38-.28a.69.69 0 0 1-.12-.41c0-.14.04-.27.11-.38a.9.9 0 0 1 .3-.27c.13-.08.28-.14.45-.18.18-.04.36-.06.55-.06.44 0 .77.06 1 .2.22.14.39.3.5.48.05.08.1.14.16.2.07.04.16.06.29.06h.77a.3.3 0 0 0 .2-.08c.07-.05.1-.13.1-.22a2.05 2.05 0 0 0-.58-1.14 2.7 2.7 0 0 0-.69-.48z'
    />
  </svg>
)
