<script context="module">
  import { Preloader } from 'webkit/utils/fn';
  import { dialogs } from 'webkit/ui/Dialog'
  import Questionnaire from './QuestionnaireDialog.svelte'
  import { queryQuestionnaire, queryQuestionnaireUserAnswers } from './api'
  import {
    checkIsQuestionnaireStarted,
    checkIsQuestionnaireFinished,
  } from './utils'

  export const showQuestionnaireDialog = props =>
    dialogs.showOnce(Questionnaire, props)

  function getQuestionnaireData() {
    queryQuestionnaire()
    queryQuestionnaireUserAnswers()
  }

  export const dataPreloader = Preloader(getQuestionnaireData);
</script>

<script>
  import { onMount } from 'svelte'
  import Dialog from 'webkit/ui/Dialog'
  import { trackInteraction } from '../../utils/events'
  import Form from './Form.svelte'
  import Intro from './Intro.svelte'
  import Final from './Final.svelte'
  import { Page } from './types'

  export let page = checkIsQuestionnaireStarted()
    ? checkIsQuestionnaireFinished()
      ? Page.Final
      : Page.Do
    : Page.Intro

  let questions = []

  $: isFinalPage = page === Page.Final
  $: question = questions[page - 1] || {}

  onMount(() => {
    queryQuestionnaire().then(data => questions = data.questions)
  })
</script>

<Dialog
  {...$$props}
  noTitle={!isFinalPage}
  title={isFinalPage ? 'Thank you!' : undefined}
  class="$style.dialog {isFinalPage ? '$style.final' : ''}"
  let:closeDialog
>
  {#if page === Page.Intro}
    <Intro {closeDialog} bind:page />
  {:else if isFinalPage}
    <Final
      closeDialog={() => {
        trackInteraction({ type: 'api_questionnaire_exit_button' })
        closeDialog()
      }}
    />
  {:else}
    {#key question}
      <Form {closeDialog} {...question} bind:page />
    {/key}
  {/if}
</Dialog>

<style lang="scss">
  .dialog {
    max-width: 600px !important;
  }

  .final {
    max-width: 960px !important;
  }
</style>
