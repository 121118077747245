import React from 'react'

export default ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5322 12C5.5322 8.15179 8.65179 5.0322 12.5 5.0322C16.3482 5.0322 19.4678 8.15179 19.4678 12C19.4678 15.8482 16.3482 18.9678 12.5 18.9678C8.65179 18.9678 5.5322 15.8482 5.5322 12ZM12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM16.2294 10.1904C16.4194 9.9779 16.4011 9.65163 16.1886 9.46168C15.9761 9.27173 15.6498 9.29002 15.4599 9.50254L11.8121 13.5836L10.0807 11.7472C9.8852 11.5398 9.55857 11.5302 9.35117 11.7257C9.14378 11.9213 9.13417 12.2479 9.3297 12.4553L11.4468 14.7008C11.5459 14.8059 11.6846 14.8647 11.8291 14.8628C11.9737 14.8608 12.1107 14.7984 12.2071 14.6906L16.2294 10.1904Z"
    />
  </svg>
)
