// @ts-ignore
import { getSavedBoolean, saveBoolean } from 'webkit/utils/localStorage'

const KEY = 'ONBOARDING_STARTED'
export const checkIsOnboardingStarted = () => getSavedBoolean(KEY)
export const startOnboarding = () => saveBoolean(KEY, true)

const ONBOARDING_FINISHED_KEY = 'ONBOARDING_FINISHED'
export const checkIsOnboardingFinished = () =>
  getSavedBoolean(ONBOARDING_FINISHED_KEY)
export const finishOnboarding = () => saveBoolean(ONBOARDING_FINISHED_KEY, true)
