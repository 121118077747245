<script lang="ts">
  import { navigate } from 'gatsby-plugin-intl'
  import Svg from 'webkit/ui/Svg/svelte'
  import { trackInteraction } from '../../utils/events'
  import { Page } from './types'

  export let page: Page
  export let closeDialog

  function finishOnboarding() {
    closeDialog()
    trackInteraction({ type: 'api_onboarding_get_key_button' })
    navigate('/account')
  }
</script>

<button
  class="btn close row hv-center"
  on:click={() => {
    closeDialog()
    trackInteraction({ type: 'api_onboarding_exit_button' })
  }}
>
  <Svg id="close" w="12" />
</button>
<main class="wrapper column">
  <h3 class="h3 mrg-xl mrg--b">👋 We are here for you!</h3>

  <p class="body-2 txt-center mrg-s mrg--b">
    We use Discord as the primary channel for <br />
    communication. Our support-forum there has a lot <br />
    of useful information, you can check out questions <br />
    asked by other users, or ask your own questions
  </p>

  <a
    href="https://santiment.net/discord"
    rel="noopener noreferrer"
    target="_blank"
    data-source="onboarding"
    data-type="join_discord"
    class="btn-0 row hv-center body-2 fluid mrg-l mrg--b"
  >
    <Svg id="external-link" w="12" />
    Join Santiment’s Discord server
  </a>

  <p class="body-2 txt-center mrg-s mrg--b">
    🤙 If there’s anything else you would like to ask us, <br />
    feel free to book a support or an onboarding <br />
    session with our team using the link bellow:
  </p>

  <a
    href="https://calendly.com/santiment-team"
    rel="noopener noreferrer"
    target="_blank"
    data-source="onboarding"
    data-type="book_call"
    class="btn-0 row hv-center body-2 fluid mrg-xxl mrg--b"
  >
    <Svg id="external-link" w="12" />
    https://calendly.com/santiment-team
  </a>

  <button class="btn-1 body-2 txt-center fluid" on:click={finishOnboarding}>
    Got it! Take me to my API key
  </button>
</main>

<style lang="scss">
  .close {
    --fill: var(--waterloo);
    position: absolute;
    right: 24px;
    top: 24px;
    height: 12px;
  }

  .wrapper {
    width: 600px;
    padding: 64px 120px 80px;
  }

  a {
    padding: 4px 0;
    gap: 10px;
  }

  .btn-1 {
    height: 40px;
  }
</style>
