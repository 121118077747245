// @ts-ignore
import { query, mutate } from 'webkit/api'
// @ts-ignore
import { Cache } from 'webkit/api/cache'
import { SELECTION_ANSWER } from './constants'

//We set here SanAPI questionnaire ID
const QUESTIONNAIRE_UUID = 'cc1a0151-02dd-4648-88c0-c51052bc7ad2'

const QUESTIONNAIRE_QUERY = `
  {
    getQuestionnaire(questionnaireUuid: "${QUESTIONNAIRE_UUID}") {
      uuid
      name
      description
      questions {
        uuid
        question
        order
        answerOptions
        hasExtraOpenTextAnswer
        type
      }
    }
  }
`

const questionnaireAccessor = ({ getQuestionnaire }) => getQuestionnaire
export const queryQuestionnaire = () =>
  query(QUESTIONNAIRE_QUERY).then(questionnaireAccessor)

const QUESTIONNAIRE_USER_ANSWERS_QUERY = `
  {
    getQuestionnaireUserAnswers(questionnaireUuid: "${QUESTIONNAIRE_UUID}") {
      uuid
      answer
      question{
        uuid
        question
        type
        order
      }
    }
  }
`

const questionnaireUserAnswersAccessor = ({ getQuestionnaireUserAnswers }) =>
  getQuestionnaireUserAnswers.reduce(
    (obj, { question, ...rest }) =>
      Object.assign(obj, {
        [question.uuid]: {
          ...rest,
          type: question.type,
        },
      }),
    {}
  )

const questionnaireUserAnswersForOnboardingAccessor = ({
  getQuestionnaireUserAnswers,
}) =>
  getQuestionnaireUserAnswers.reduce((obj, { question, answer }) => {
    if ([2, 3, 4, 5].includes(question.order)) {
      return Object.assign(obj, {
        [question.order]: answer[SELECTION_ANSWER],
      })
    }

    return obj
  }, {})

export const queryQuestionnaireUserAnswers = (
  reqOptions = {},
  isOnboarding = false
) =>
  query(QUESTIONNAIRE_USER_ANSWERS_QUERY, reqOptions).then(
    isOnboarding
      ? questionnaireUserAnswersForOnboardingAccessor
      : questionnaireUserAnswersAccessor
  )

const QUESTIONNAIRE_ANSWER_MUTATION = `
  mutation createQuestionnaireAnswer($uuid: String!, $answer: json!) {
    createQuestionnaireAnswer(
      questionUuid: $uuid
      params: {answer: $answer}) {
        answer
        uuid
    }
  }
`

const UPDATE_QUESTIONNAIRE_ANSWER_MUTATION = `
  mutation updateQuestionnaireAnswer($uuid: String!, $answer: json!) {
    updateQuestionnaireAnswer(
      answerUuid: $uuid
      params: {answer: $answer}) {
        answer
        uuid
    }
  }
`

const accessor = ({ createQuestionnaireAnswer, updateQuestionnaireAnswer }) =>
  createQuestionnaireAnswer || updateQuestionnaireAnswer

export const mutateQuestionnaireAnswer = async (
  uuid: string,
  answer: Record<string, any>,
  answerUuid: string
) =>
  mutate(
    answerUuid
      ? UPDATE_QUESTIONNAIRE_ANSWER_MUTATION
      : QUESTIONNAIRE_ANSWER_MUTATION,
    {
      variables: {
        uuid: answerUuid || uuid,
        answer: JSON.stringify(answer),
      },
    }
  ).then(accessor)

const DATASET_QUERY = `
  {
    getPresignedS3Url(object: "new-users/Santiment datasets.zip")
  }
`

const datasetAccessor = ({ getPresignedS3Url }) => getPresignedS3Url
export const queryDataset = () => query(DATASET_QUERY).then(datasetAccessor)
