<script lang="ts">
  import { navigate } from 'gatsby-plugin-intl'
  import { queryCurrentUser } from 'webkit/api/analytics'
  import { trackInteraction } from '../../utils/events'
  import IntroLayout from './IntroLayout.svelte'
  import { startQuestionnaire } from './utils'
  import { Page } from './types'

  export let page: Page
  export let closeDialog

  function onStart() {
    queryCurrentUser().then(currentUser => {
      if (!currentUser) {
        navigate('/login')
        return closeDialog()
      }

      trackInteraction({ type: 'api_start_questionnaire_button' })
      page = Page.Do
      return startQuestionnaire()
    })
  }
</script>

<IntroLayout
  title="Start the questionnaire"
  img={{ id: 'san-api/questionnaire', w: 220, h: 225 }}
  closeDialog={() => {
    trackInteraction({ type: `api_close_questionnaire_q${page}_button` })
    closeDialog()
  }}
>
  <p class="body-2 c-fiord txt-center">
    After submiting the questionnaire you will get <br />
    instant access to our free package of datasets, <br />
    containing up to 3 years of data <br />
    for 20 metrics on top of 16 assets
  </p>

  <button class="btn-1 txt-center body-2 action fluid" on:click={onStart}>
    Start
  </button>
</IntroLayout>

<style>
  .action {
    max-width: 164px;
    height: 40px;
    margin-top: 40px;
  }
</style>
