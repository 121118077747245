import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import Button from '@santiment-network/ui/Button'
import { trackPurchase } from '../../utils/events'
import styles from './index.module.scss'

function onGetAccessClick() {
  window.gtag('event', 'login_action_call', {
    location: 'Plan Card',
    text: 'Upgrade now',
  })
  trackPurchase({ type: 'plan_selected' })
}

function getIntlKey({ name, sameAsUserPlan, isSubscriptionCanceled }) {
  if (sameAsUserPlan) return 'your_plan'
  if (name === 'CUSTOM') return 'contact'
  if (name === 'FREE') return 'free'
  if (isSubscriptionCanceled) return 'upgrade_now'

  return 'get_started'
}

function getLinkProps({ sameAsUserPlan, isSubscriptionCanceled }) {
  if (sameAsUserPlan) return {}

  return {
    as: Link,
    to: isSubscriptionCanceled ? '/account#subscription?renew' : '/account',
    onClick: isSubscriptionCanceled
      ? () => trackPurchase({ type: 'plan_selected' })
      : onGetAccessClick,
  }
}

const PlanRestrictBtn = ({
  intl,
  name,
  sameAsUserPlan,
  isSubscriptionCanceled,
}) => {
  const linkProps = getLinkProps({ sameAsUserPlan, isSubscriptionCanceled })
  const key = getIntlKey({ name, sameAsUserPlan, isSubscriptionCanceled })

  return (
    <Button
      fluid
      accent="blue"
      variant="fill"
      className={styles.link}
      disabled={sameAsUserPlan}
      {...linkProps}
    >
      {intl.formatMessage({ id: `cta.${key}` })}
    </Button>
  )
}

export default injectIntl(PlanRestrictBtn)
