import { Page } from './types'

export const pages = {
  [Page.Do]: {
    title: 'I do:',
    variants: [
      'Work at a fund, or a trading firm, or a VC firm',
      'Manage my own portfolio',
      'Create trading strategies or bots for others',
      'Use the data in my app',
      'Research or journalism',
      'Other',
    ],
  },
  [Page.Time]: {
    title: 'Time horizon of my strategy mainly is:',
    variants: [
      'Longer term (daily and above)',
      'Shorter term (hourly and below)',
      'Somewhere in the middle (between hourly and daily)',
      'I do all of them',
      'No strategy, research purposes only',
    ],
  },
  [Page.Data]: {
    title: 'I’d like to get data via:',
    variants: ['Python library', 'HTTP requests', 'Other'],
  },
  [Page.SQL]: {
    title: 'I’d like to use SQL for data exploration:️',
    variants: ['Yes', 'No'],
  },
  [Page.Charts]: {
    title: 'I’d like to explore the data visually through charts:',
    variants: ['Yes', 'No'],
  },
  [Page.Amount]: {
    title: 'I have interest in the following amount of cryptocurrencies:',
    variants: [
      'Only up to 5-10 top coins',
      'Coins traded on major exchanges',
      'As many coins as possible',
    ],
  },
  [Page.Provider]: {
    title: 'Before Santiment, the crypto data provider I used most is:',
    variants: [
      'Cryptocompare',
      'Nansen',
      'Glassnode',
      'Cryptoquant',
      'Messari',
      'Token terminal',
      'None',
      'Other',
    ],
  },
}

export const OPEN_TEXT_ANSWER = 'open_text_answer'
export const SELECTION_ANSWER = 'answer_selection'
