// @ts-ignore
import { getSavedBoolean, saveBoolean } from 'webkit/utils/localStorage'
import { OPEN_TEXT_ANSWER, SELECTION_ANSWER } from './constants'
import { QuestionType } from './types'

const KEY = 'QUESTIONNAIRE_STARTED'
export const checkIsQuestionnaireStarted = () => getSavedBoolean(KEY)
export const startQuestionnaire = () => saveBoolean(KEY, true)

const QUESTIONNAIRE_FINISHED_KEY = 'QUESTIONNAIRE_FINISHED'
export const checkIsQuestionnaireFinished = () =>
  getSavedBoolean(QUESTIONNAIRE_FINISHED_KEY)
export const finishQuestionnaire = () =>
  saveBoolean(QUESTIONNAIRE_FINISHED_KEY, true)

export function formatAnswer(data: Record<string, any>, type: QuestionType) {
  const values = {
    other: '',
    answer: '' as string | string[],
  }

  if (!data)
    return {
      ...values,
      answer: type === QuestionType.MULTIPLE_SELECT ? [] : '',
      prevAnswer: values,
      answerUuid: '',
    }

  const userAnswer = data.answer
  const answerUuid = data.uuid

  if (type === QuestionType.MULTIPLE_SELECT) {
    if (userAnswer[OPEN_TEXT_ANSWER]) {
      values.other = userAnswer[OPEN_TEXT_ANSWER]
      delete userAnswer[OPEN_TEXT_ANSWER]
    }

    values.answer = Object.values(userAnswer)

    return {
      ...values,
      prevAnswer: values,
      answerUuid,
    }
  }

  if (userAnswer[OPEN_TEXT_ANSWER]) {
    values.answer = OPEN_TEXT_ANSWER
    values.other = userAnswer[OPEN_TEXT_ANSWER]

    return {
      ...values,
      prevAnswer: values,
      answerUuid,
    }
  }

  values.answer = userAnswer[SELECTION_ANSWER]

  return {
    ...values,
    prevAnswer: values,
    answerUuid,
  }
}

export function formatMultipleAnswers(answer, other) {
  return answer.reduce((acc, curr) => {
    if (curr === OPEN_TEXT_ANSWER) {
      return {
        ...acc,
        [OPEN_TEXT_ANSWER]: other,
      }
    }

    const selectionAnswer = acc[SELECTION_ANSWER] || []

    return {
      ...acc,
      [SELECTION_ANSWER]: [...selectionAnswer, curr],
    }
  }, {})
}
