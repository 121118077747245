<script lang="ts">
  import { showPaymentDialog } from 'webkit/ui/PaymentDialog/index.svelte'
  import { showPlanChangeDialog } from 'webkit/ui/Pricing/PlanChangeDialog.svelte'
  import { checkIsUpgrade } from 'webkit/ui/Pricing/utils'

  export let label: string
  export let plan
  export let plans
  export let subscription
  export let isSinglePlan = false

  $: isUpgrade = checkIsUpgrade(plan, subscription)

  function onClick() {
    if (subscription) {
      return showPlanChangeDialog({ isUpgrade, plan, subscription })
    }

    showPaymentDialog({
      source: 'api-plans',
      plan: plan.name,
      interval: plan.interval,
      planData: plan,
      plans,
      isSinglePlan,
    })
  }
</script>

<button class="plan-button row hv-center fluid btn-1" on:click={onClick}>
  {label}
</button>

<style>
  button {
    height: 40px;
  }
</style>
