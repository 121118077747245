import React, { useEffect, useState } from 'react'
import Label from '@santiment-network/ui/Label'
import Button from '@santiment-network/ui/Button'
import Settings from './Settings'
import { checkIsQuestionnaireFinished } from '../Questionnaire/utils'
import styles from './SettingsAPIKeys.module.scss'
import { queryDataset } from '../Questionnaire/api'

const SettingsGetStarted = ({ apikeys = [], generateAPIKey, revokeAPIKey }) => {
  const [datasetUrl, setDatasetUrl] = useState('')

  useEffect(() => {
    queryDataset().then(data => setDatasetUrl(data))
  }, [])

  const isQuestionnaireFinished = checkIsQuestionnaireFinished()

  return (
    <Settings id="get-started" header="Get started">
      <Settings.Row>
        <div className={styles.setting__left}>
          <Label className={styles.setting__description} accent="waterloo">
            Read our guide "Getting started for developers"
          </Label>
        </div>
        <Button
          as="a"
          variant="fill"
          accent="neuro"
          target="_blank"
          rel="noopener noreferrer"
          href="https://academy.santiment.net/for-developers/"
          className={styles.text}
        >
          Read the article
        </Button>
      </Settings.Row>
      <Settings.Row>
        <div className={styles.setting__left}>
          <Label className={styles.setting__description} accent="waterloo">
            Get crypto data directly into Python
          </Label>
        </div>
        <Button
          as="a"
          variant="fill"
          accent="neuro"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/santiment/sanpy"
          className={styles.text}
        >
          Read the readme
        </Button>
      </Settings.Row>
      {isQuestionnaireFinished && (
        <>
          <Settings.Row>
            <div className={styles.setting__left}>
              <Label className={styles.setting__description} accent="waterloo">
                Read "SanAPI" documentation
              </Label>
            </div>
            <Button
              as="a"
              variant="fill"
              accent="neuro"
              target="_blank"
              rel="noopener noreferrer"
              href="https://academy.santiment.net/sanapi/#overview"
              className={styles.text}
            >
              Read the article
            </Button>
          </Settings.Row>
          <Settings.Row>
            <div className={styles.setting__left}>
              <Label className={styles.setting__description} accent="waterloo">
                Use SQL to explore data
              </Label>
            </div>
            <Button
              as="a"
              variant="fill"
              accent="neuro"
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.santiment.net/queries"
              className={styles.text}
            >
              Start
            </Button>
          </Settings.Row>
          <Settings.Row>
            <div className={styles.setting__left}>
              <Label className={styles.setting__description} accent="waterloo">
                Get our dataset
              </Label>
            </div>
            <Button
              as="a"
              variant="fill"
              accent="neuro"
              target="_blank"
              rel="noopener noreferrer"
              href={datasetUrl}
              className={styles.text}
            >
              Download
            </Button>
          </Settings.Row>
        </>
      )}
    </Settings>
  )
}

export default SettingsGetStarted
