export const POINTS = [
  'Found other tool that fits my needs better',
  'I don’t need all the features',
  'Too difficult to use',
  'Too expensive',
  'Other'
]

export const Event = {
  SelectReason: 'cancel_subscribtion_select_reason',
  GiveFeedback: 'cancel_subscribtion_give_feedback'
}
