export const IS_STAGE =
  typeof window !== 'undefined' &&
  process.env.BACKEND_URL === 'https://api-stage.santiment.net'

export const IS_PROD =
  typeof window !== 'undefined' &&
  process.env.BACKEND_URL === 'https://api.santiment.net'

export function toSnakeCase(str) {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')
}
