import React from 'react'
import Button from '@santiment-network/ui/Button'

export const useIntercomClick = () => {
  return message => {
    if (window.Intercom) {
      if (message && typeof message === 'string') {
        window.Intercom('showNewMessage', message)
      } else {
        window.Intercom('show')
      }
    } else {
      window.open('/support', '_blank')
    }
  }
}


const ContactUs = ({ message, children, onClick, ...rest }) => {
  const intercomHandler = useIntercomClick()

  return (
    <Button
      onClick={() => {
        intercomHandler(message)
        onClick && onClick()
      }}
      {...rest}
    >
      {children || 'Contact us'}
    </Button>
  )
}

export default ContactUs
