<script>
  import {
    showQuestionnaireDialog,
    dataPreloader,
  } from '../Questionnaire/QuestionnaireDialog.svelte'
  import { showOnboardingDialog } from '../Onboarding/OnboardingDialog.svelte'
  import { checkIsOnboardingStarted } from '../Onboarding/utils'
  import { trackInteraction } from '../../utils/events'

  let isOnboarding = checkIsOnboardingStarted()

  function onClick() {
    if (checkIsOnboardingStarted()) {
      showOnboardingDialog()
      trackInteraction({ type: 'onboarding_start_button' })
    } else {
      showQuestionnaireDialog()
      trackInteraction({ type: 'api_take_questionnaire_button' })
    }
  }
</script>

<button
  class="row btn-2 txt-center body-3 action"
  on:click={onClick}
  use:dataPreloader
>
  {isOnboarding ? 'Start your customised onboarding' : 'Take the questionnaire'}
</button>

<style>
  .action {
    --color: var(--white);
    --color-hover: var(--mystic);
  }
</style>
