import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { Query } from 'react-apollo'
import cx from 'classnames'
import { CURRENT_USER_QUERY } from '../../gql/user'
import Features from './Features'
import PlanRestrictBtn from './PlanRestrictBtn'
import { PLANS_QUERY } from '../../gql/plans'
import PLANS from './prices'
import {
  findNeuroPlan,
  getCurrentNeuroSubscription,
  checkIsBusiness,
  groupByInterval,
} from '../../utils/plans'
import { tr, trStr } from '../../utils/translate'
import styles from './index.module.scss'
import { calculateYearDiscount, formatPlanPrice } from './utils'

export default injectIntl(({ intl, classes = {}, onDialogClose }) => {
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ data: { currentUser } = {} }) => {
        const subscription = getCurrentNeuroSubscription(currentUser)
        const userPlan = subscription && subscription.plan.id
        const isSubscriptionCanceled =
          subscription && subscription.cancelAtPeriodEnd

        return (
          <Query query={PLANS_QUERY}>
            {({ data: { productsWithPlans = [] } = {} }) => {
              const neuro = productsWithPlans.find(findNeuroPlan)
              if (!neuro) {
                return null
              }

              const allPlans = neuro.plans.filter(
                plan => !plan.isDeprecated && checkIsBusiness(plan)
              )

              const groupedPlans = groupByInterval(allPlans)

              return (
                <>
                  <div className={styles.cards}>
                    {groupedPlans.map((planGroup, idx) => {
                      const { month, year } = planGroup
                      const { name } = month || year
                      const card = PLANS[name]
                      const mainPlan = year
                      const { id } = mainPlan

                      const sameAsUserPlan =
                        month.id === userPlan || year.id === userPlan
                      const amount = 0

                      const monthPrice = formatPlanPrice(month.amount)
                      const yearPrice = formatPlanPrice(year.amount)
                      const yearDiscount = calculateYearDiscount(
                        month.amount,
                        year.amount
                      )

                      const intlId = `plan.${name.toLowerCase()}`

                      const title = trStr(intl, intlId + '.title')

                      return (
                        <div
                          className={cx(
                            styles.card,
                            classes.card,
                            idx === 0 && styles.card_first,
                            idx === groupedPlans.length - 1 && styles.card_last,
                            card.isPopular && styles.card_popular,
                            sameAsUserPlan && styles.card_active
                          )}
                          key={name}
                        >
                          <div className={styles.card__top}>
                            <h3 className={styles.card__title}>
                              {title}
                              {card.isPopular && (
                                <span className={styles.popular}>
                                  {tr('plan.popular')}
                                </span>
                              )}
                            </h3>
                          </div>
                          <div className={styles.desc}>
                            {tr(intlId + '.desc')}
                          </div>
                          <div className={styles.details}>
                            <div className={styles.month_price}>
                              {monthPrice ? (
                                <>
                                  <span>{monthPrice}</span>
                                  <span className={styles.month_price__month}>
                                    / mo
                                  </span>
                                </>
                              ) : (
                                'Get a quote'
                              )}
                            </div>
                            <div className={styles.year_price}>
                              {yearPrice ? (
                                <>
                                  <span className={styles.year_price__price}>
                                    {yearPrice}
                                  </span>
                                  <span>/ year</span>
                                  <span className={styles.year_price__discount}>
                                    - {yearDiscount}%🎉
                                  </span>
                                </>
                              ) : (
                                'Based on your needs'
                              )}
                            </div>
                            {!currentUser ||
                            sameAsUserPlan ||
                            isSubscriptionCanceled ? (
                              <PlanRestrictBtn
                                sameAsUserPlan={sameAsUserPlan}
                                name={name}
                                isSubscriptionCanceled={isSubscriptionCanceled}
                              />
                            ) : (
                              <card.Component
                                label={card.link}
                                plan={month}
                                plans={[month, year]}
                                subscription={subscription}
                                isSinglePlan
                              />
                            )}
                            <Features
                              intl={intl}
                              data={card.features}
                              classes={styles}
                              intlId={`plan.${name.toLowerCase()}.feature.`}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
})
