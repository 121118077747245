import React from 'react'

export const ProductsToggle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="2" cy="2" r="2" fill="#7A809A" />
      <circle cx="8" cy="2" r="2" fill="#7A809A" />
      <circle cx="14" cy="2" r="2" fill="#7A809A" />
      <circle cx="2" cy="8" r="2" fill="#7A809A" />
      <circle cx="8" cy="8" r="2" fill="#7A809A" />
      <circle cx="14" cy="8" r="2" fill="#7A809A" />
      <circle cx="2" cy="14" r="2" fill="#7A809A" />
      <circle cx="8" cy="14" r="2" fill="#7A809A" />
      <circle cx="14" cy="14" r="2" fill="#7A809A" />
    </svg>
  )
}
