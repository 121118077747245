import { client } from '../apollo/client'
import { USER_PAYMENTS } from '../gql/user'

export const formatPrice = (price, name, billing) => {
  if (name === 'FREE') return ['$0', '', 0]
  if (!price) return ['Custom']

  const devider = 100 * (billing === 'year' ? 12 : 1)
  const displayedPrice = parseInt(price / devider, 10)

  return [`$${displayedPrice}`, '/mo', displayedPrice]
}

export const apiProductId = '1'
export const findNeuroPlan = ({ id }) => id === apiProductId

export const getCurrentNeuroSubscription = user => {
  if (!user) return
  const { subscriptions: subs } = user
  if (!subs) return

  return subs.find(
    ({
      plan: {
        product: { id },
      },
    }) => id === apiProductId
  )
}

export const getBilling = () =>
  client.query({ query: USER_PAYMENTS, fetchPolicy: 'network-only' })

export const getAlternativeBillingPlan = (
  plans,
  currentPlan,
  currentInterval
) =>
  plans
    .filter(({ isDeprecated, isPrivate }) => !isDeprecated && !isPrivate)
    .find(
      ({ name, interval }) =>
        name === currentPlan && interval !== currentInterval
    )

const BUSINESS_PLANS = ['BUSINESS_PRO', 'BUSINESS_MAX', 'CUSTOM']

export const checkIsBusiness = ({ name }) => BUSINESS_PLANS.includes(name)

export const groupByInterval = plans => {
  const groupedPans = plans.reduce(
    (prev, plan) => ({
      ...prev,
      [plan.name]: { ...prev[plan.name], [plan.interval]: plan },
    }),
    {}
  )

  return BUSINESS_PLANS.map(plan => groupedPans[plan])
}

export const getPlanFromGroup = group => group.month || group.year
