<script>
  import { onDestroy, onMount } from 'svelte'
  import { checkIsQuestionnaireStarted } from '../Questionnaire/utils'
  import { queryCurrentUser } from '../Onboarding/api'
  import { showBannerDialog } from './BannerDialog.svelte'
  import { checkWasBannerClosedOnce } from './utils'

  let timer

  onMount(() => {
    queryCurrentUser().then(currentUser => {
      if (currentUser && !currentUser.privacyPolicyAccepted) return

      const currentPath = window.location.pathname
      const isAvailablePaths =
        currentPath !== '/login' && currentPath !== '/sign-up'

      if (
        isAvailablePaths &&
        !checkIsQuestionnaireStarted() &&
        !checkWasBannerClosedOnce()
      ) {
        timer = setTimeout(showBannerDialog, 2000)
      }
    })
  })

  onDestroy(() => {
    clearTimeout(timer)
  })
</script>
