<script context="module">
  import { Preloader } from 'webkit/utils/fn';
  import { queryCurrentUser } from './api'

  export const dataPreloader = Preloader(queryCurrentUser);
</script>

<script lang="ts">
  import { navigate } from 'gatsby-plugin-intl'
  import { onMount } from 'svelte'
  import Svg from 'webkit/ui/Svg/svelte'
  import { trackInteraction } from '../../utils/events'
  import IntroLayout from '../Questionnaire/IntroLayout.svelte'
  import { startOnboarding } from './utils'
  import { Page } from './types'

  export let page: Page
  export let closeDialog

  let username = 'username'

  function initOnboarding() {
    startOnboarding()
    page = Page.Metrics
    trackInteraction({ type: 'api_onboarding_next_button' })
  }

  onMount(() => {
    queryCurrentUser().then(currentUser => {
      if (!currentUser) {
        navigate('/login')
        return closeDialog()
      } else {
        username = currentUser.username
      }
    })
  })
</script>

<IntroLayout
  title={username ? `Welcome, ${username}!` : 'Welcome!'}
  img={{ id: 'san-api/onboarding', w: 280, h: 241 }}
  closeDialog={() => {
    trackInteraction({ type: 'api_onboarding_exit_button' })
    closeDialog()
  }}
>
  <p class="body-2 txt-center mrg-m mrg--b">
    You can start exploring Santiment API by getting <br />
    started with our most recent documentation.
  </p>

  <a
    href="https://academy.santiment.net/sanapi/#overview"
    rel="noopener noreferrer"
    target="_blank"
    data-source="onboarding"
    data-type="api_docs_open"
    class="btn-0 row hv-center body-2 fluid mrg-xxl mrg--b"
  >
    <Svg id="external-link" w="12" />
    Open Santiment API Documentation
  </a>

  <button
    class="btn-1 txt-center body-2 action fluid"
    on:click={initOnboarding}
  >
    Best metrics to start with
  </button>

  <p class="caption c-fiord txt-center mrg-l mrg--t">
    * All links will be available in <br />
    your account settings
  </p>
</IntroLayout>

<style>
  a {
    padding: 4px 0;
    gap: 10px;
  }

  .action {
    max-width: 212px;
    height: 40px;
  }
</style>
