<script lang="ts">
  import { Page } from './types'
  import { finishOnboarding } from './utils'
  import { trackInteraction } from '../../utils/events'

  export let page: Page
  export let count: number

  function changePage(newPage: Page) {
    if (newPage === Page.Final) {
      finishOnboarding()
    }

    page = newPage
  }

  function setDots(endCount) {
    return Object.keys(Page).slice(1, endCount + 2)
  }

  function handleNext() {
    const nextPage = page + 1
    if (nextPage === count + 2) changePage(Page.Final)
    else changePage(page + 1)

    trackInteraction({ type: 'api_onboarding_next_button' })
  }

  $: dots = setDots(count)
</script>

<nav class="row v-center body-2">
  <button
    class="pagination next btn-1 row hv-center mrg-l mrg--r"
    on:click={handleNext}
  >
    Next
  </button>

  <button
    class="pagination prev btn-2 txt-center"
    class:disabled={page === Page.Metrics}
    on:click={() => {
      changePage(page - 1)
      trackInteraction({ type: 'api_onboarding_previous_button' })
    }}
  >
    Previous
  </button>

  <div class="dots row v-center mrg-a mrg--l">
    {#each dots as d}
      {@const active = Number(d) === page}
      <hr class="dot" class:active />
    {/each}
  </div>
</nav>

<style>
  .pagination {
    gap: 10px;
    height: 40px;
  }

  .next {
    width: 124px;
  }

  .prev {
    --color-hover: var(--blue-hover);
    width: 101px;
  }

  .dots {
    gap: 8px;
  }

  .dot {
    border: none;
    width: 6px;
    height: 6px;
    background: var(--blue-light-3);
    border-radius: 100%;
  }

  .active {
    background: var(--blue);
  }
</style>
