export enum Page {
  Intro,
  Do,
  Time,
  Data,
  SQL,
  Charts,
  Amount,
  Provider,
  Final,
}

export enum QuestionType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
}
