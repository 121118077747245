<script lang="ts">
  import Svg from 'webkit/ui/Svg/svelte'
  import { Page } from './types'

  let className = ''
  export { className as class }
  export let page: Page
  export let closeDialog
  export let img: {
    id: string
    w: number
    h: number
  }
  export let title: string
</script>

<button class="btn close row hv-center" on:click={closeDialog}>
  <Svg id="close" w="12" />
</button>
<main class="column hv-center fluid">
  <Svg illus {...img} class="$style.img" />

  <h3 class="h3 mrg-l mrg--b txt-center">{title}</h3>

  <slot />
</main>

<style>
  .close {
    --fill: var(--waterloo);
    position: absolute;
    right: 24px;
    top: 24px;
    height: 12px;
  }

  main {
    padding: 64px 120px 80px;
  }

  .img {
    margin-bottom: 40px;
  }
</style>
