import React, { useContext } from 'react'
import { Query } from 'react-apollo'
import { updateAmplitude } from 'webkit/analytics/amplitude'
import { CURRENT_USER_QUERY } from '../gql/user'
import { MixpanelContext } from '../apollo/wrap-root-element'

const updateIntercom = () => {
  if (typeof window !== 'undefined') {
    window.Intercom('update')

    // Wait for the iframe to become ready (max 30 seconds)
    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      const iframe = document.querySelector('.intercom-launcher-frame')

      if (iframe) {
        const intercomLauncher = iframe.contentDocument.querySelector(
          '#intercom-container .intercom-launcher'
        )
        intercomLauncher.setAttribute(
          'style',
          'background: #5275ff !important;'
        )

        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }
}

const Intercom = ({ children }) => {
  const mixpanel = useContext(MixpanelContext)

  updateIntercom()
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ data: { currentUser } = {}, loading }) => {
        if (!loading) {
          const { email, username: name, id } = currentUser || {}

          updateAmplitude(id, name, email)
          // TODO: uncomment after prod test
          // mixpanel.identify(id)

          window.Intercom('boot', {
            app_id: 'cyjjko9u',
            user_id: id,
            email,
            name,
          })
        }

        return children
      }}
    </Query>
  )
}

export default Intercom
