import React, { useEffect } from 'react'
import cx from 'classnames'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import Icon from '@santiment-network/ui/Icon'
import Button from '@santiment-network/ui/Button'
import { Query } from 'react-apollo'
import { CURRENT_USER_QUERY } from '../../gql/user'
import styles from './AccountBtn.module.scss'

const btnProps = {
  false: {
    accent: 'blue',
    className: styles.login,
    children: intl =>
      intl.formatMessage({
        id: 'cta.sign_up',
      }),
    onClick: () => {
      window.gtag('event', 'login_action_call', {
        location: 'Navbar',
        text: 'Sign up',
      })
    },
  },
  true: {
    className: styles.account,
    children: <Icon type="profile" className={styles.profileIcon} />,
  },
}

const Wrapper = ({ currentUser, children }) => {
  useEffect(() => {
    if (currentUser) {
      if (window.$FPROM) {
        const { id, email, username } = currentUser
        window.$FPROM.trackSignup({ id, email, username })
      }
    }
  }, [currentUser])

  return children
}

const AccountBtn = ({ intl, isAccountPage }) => {
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ data: { currentUser } = {} }) => {
        const { children, ...props } = btnProps[Boolean(currentUser)]

        return (
          <Wrapper currentUser={currentUser}>
            <Button
              as={Link}
              to="/account"
              variant="fill"
              isActive={isAccountPage}
              {...props}
              data-source="navbar"
              data-type="profile"
              className={cx(
                props.className,
                styles.profileBtn,
                'row hv-center'
              )}
            >
              {typeof children === 'function' ? children(intl) : children}
            </Button>
          </Wrapper>
        )
      }}
    </Query>
  )
}

export default injectIntl(AccountBtn)
