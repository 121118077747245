<script lang="ts">
  import { OPEN_TEXT_ANSWER } from './constants'

  export let option: string[]
  export let isOther: boolean
  export let answer: string[]
  export let other: string

  function onTextareaFocus() {
    if (!answer.includes(OPEN_TEXT_ANSWER)) answer.push(OPEN_TEXT_ANSWER)
  }

  function onChange({ target }) {
    const { value, checked } = target
    if (checked) {
      answer = [...answer, value]
    } else {
      answer = answer.filter(item => item !== value)
    }
  }

  $: checked = answer.includes(option[0])
  $: checkedOther = answer.includes(OPEN_TEXT_ANSWER)
</script>

<label class="btn row v-center">
  <input type="checkbox" value={option[0]} {checked} on:change={onChange} />
  {option[1]}
</label>
{#if isOther}
  <label class="btn row v-center">
    <input
      type="checkbox"
      value={OPEN_TEXT_ANSWER}
      checked={checkedOther}
      on:change={onChange}
    />
    Other
  </label>
  <textarea
    placeholder="Write your text here..."
    bind:value={other}
    on:focus={onTextareaFocus}
  />
{/if}

<style lang="scss">
  label {
    gap: 12px;

    &:hover {
      input {
        border-color: var(--blue-hover);
      }
    }
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--porcelain);
    border-radius: 4px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 2px;
      transform: scale(0);
      transition: 100ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--blue);
    }

    &:checked {
      border-color: var(--blue);

      &::before {
        transform: scale(1);
      }
    }
  }

  textarea {
    height: 80px;
    padding: 10px 12px;
    border: 1px solid var(--porcelain);
    border-radius: 4px;
    resize: none;
  }
</style>
