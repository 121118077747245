<script lang="ts">
  import Svg from 'webkit/ui/Svg/svelte'
  import { trackInteraction } from '../../utils/events'
  import { toSnakeCase } from '../../utils'
  import Pagination from './Pagination.svelte'
  import { Page } from './types'

  const metrics = {
    1: [
      {
        title: 'MVRV 30d+',
        href: 'https://academy.santiment.net/metrics/mvrv-ratio/',
      },
      {
        title: 'Social volume',
        href: 'https://academy.santiment.net/metrics/social-volume/',
      },
      {
        title: 'Weighted sentiment',
        href:
          'https://academy.santiment.net/metrics/sentiment-metrics/#sentiment-analysis',
      },
      {
        title: 'Daily active addresses',
        href: 'https://academy.santiment.net/metrics/daily-active-addresses/',
      },
      {
        title: 'Development activity',
        href:
          'https://academy.santiment.net/metrics/development-activity/#development-activity-metric',
      },
    ],
    2: [
      {
        title: 'Labeled flow',
        href: 'https://academy.santiment.net/metrics/labeled-flow/',
      },
      {
        title: 'Exchange funds flow',
        href: 'https://academy.santiment.net/metrics/exchange-funds-flow/',
      },
      {
        title: 'Active addresses 1h',
        href: 'https://academy.santiment.net/metrics/active-addresses-24h',
      },
    ],
    3: [
      {
        title: 'Active addresses 24h',
        href: 'https://academy.santiment.net/metrics/active-addresses-24h',
      },
      {
        title: 'MVRV 1d-7d',
        href: 'https://academy.santiment.net/metrics/mvrv-ratio/',
      },
      {
        title: 'Labeled flow',
        href: 'https://academy.santiment.net/metrics/labeled-flow/',
      },
      {
        title: 'Funding rates',
        href: 'https://academy.santiment.net/metrics#derivatives',
      },
    ],
  }

  export let page: Page
  export let closeDialog
  export let answer: number
  export let count: number

  $: metricsNum = [1, 2, 3].includes(+answer) ? answer : 1
</script>

<button
  class="btn close row hv-center"
  on:click={() => {
    closeDialog()
    trackInteraction({ type: 'api_q1_close_onboarding_button' })
  }}
>
  <Svg id="close" w="12" />
</button>
<main class="wrapper column">
  <h3 class="h3 mrg-xl mrg--b">Metrics to get you started</h3>

  <div class="column metrics body-2">
    <p class="mrg-l mrg--b">
      Based on our experience, the best metrics to start getting familiar with
      the variety of our data are:
    </p>
    {#each metrics[metricsNum] as metric, i}
      {@const isLast = metrics[metricsNum].length - 1 === i}
      <a
        href={metric.href}
        rel="noopener noreferrer"
        target="_blank"
        data-source="onboarding"
        data-type={`${toSnakeCase(metric.title)}_doc`}
        class="btn-0 row v-center body-2 fluid {isLast ? '' : 'mrg-s mrg--b'}"
      >
        <Svg id="external-link" w="12" />
        {metric.title}
      </a>
    {/each}
  </div>

  <Pagination {count} bind:page />
</main>

<style lang="scss">
  .close {
    --fill: var(--waterloo);
    position: absolute;
    right: 24px;
    top: 24px;
    height: 12px;
  }

  .wrapper {
    width: 600px;
    padding: 64px 120px 60px;
  }

  .metrics {
    background: var(--white);
    padding: 24px 32px;
    border-radius: 4px;
    margin-bottom: 40px;
  }

  a {
    padding: 4px 0;
    gap: 10px;
  }
</style>
