<script lang="ts">
  import Svg from 'webkit/ui/Svg/svelte'
  import { trackInteraction } from '../../utils/events'
  import Pagination from './Pagination.svelte'
  import { Page } from './types'

  export let page: Page
  export let title: string
  export let description: string
  export let linkTitle: string
  export let link: string
  export let img: string
  export let count: number
  export let closeDialog
</script>

<button
  class="btn close row hv-center"
  on:click={() => {
    closeDialog()
    trackInteraction({ type: `api_q${page}_close_onboarding_button` })
  }}
>
  <Svg id="close" w="12" />
</button>
<main class="wrapper column">
  <img src={img} alt="Img" />

  <h3 class="h3 mrg-xl mrg--b">{title}</h3>

  <p class="body-2 mrg-s mrg--b">{description}</p>

  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    data-source="onboarding"
    data-type="go_to_sanbase"
    class="btn-0 row v-center fluid body-2 mrg-xxl mrg--b"
  >
    <Svg id="external-link" w="12" />
    {linkTitle}
  </a>
  <Pagination {count} bind:page />
</main>

<style lang="scss">
  .close {
    --fill: var(--waterloo);
    position: absolute;
    right: 24px;
    top: 24px;
    height: 12px;
  }

  .wrapper {
    width: 600px;
    padding: 64px 120px 60px;
  }

  img {
    width: 360px;
    height: 138px;
    margin-bottom: 40px;
    filter: drop-shadow(0px 2px 24px rgba(24, 27, 43, 0.04))
      drop-shadow(1px 3px 7px rgba(47, 53, 77, 0.05));
  }

  a {
    padding: 4px 0;
    gap: 10px;
  }
</style>
