/**
 *
 * @param {number} price
 */
export function formatPlanPrice(price) {
  if (!price) return null

  const displayedPrice = parseInt(price / 100, 10)

  return `$${displayedPrice}`
}

/**
 *
 * @param {number} monthAmount
 * @param {number} yearAmount
 */
export function calculateYearDiscount(monthAmount, yearAmount) {
  const discount = yearAmount / (monthAmount * 12)
  return Math.round(100 - discount * 100)
}
