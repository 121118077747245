<script lang="ts">
  import Svg from 'webkit/ui/Svg/svelte'
  import { Page } from './types'
  import { OPEN_TEXT_ANSWER } from './constants'
  import { finishQuestionnaire, formatMultipleAnswers } from './utils'
  import { mutateQuestionnaireAnswer } from './api'
  import { trackInteraction } from '../../utils/events'

  export let page: Page
  export let answer: string | string[]
  export let other: string
  export let uuid: string
  export let answerUuid: string
  export let prevAnswer: Record<string, string>
  export let isMultiple: boolean

  let pages = Object.keys(Page).slice(1, 8)
  let loading = false

  function submitAnswer() {
    let userAnswer

    if (isMultiple) {
      userAnswer = formatMultipleAnswers(answer, other)
    } else if (other) {
      userAnswer = { [answer]: other }
    } else {
      userAnswer = { answer_selection: answer }
    }

    return mutateQuestionnaireAnswer(uuid, userAnswer, answerUuid)
  }

  async function changePage(newPage: Page) {
    const isAnswerChanged =
      prevAnswer && (prevAnswer.answer !== answer || prevAnswer.other !== other)

    if (newPage > page && isAnswerChanged) {
      loading = true
      await submitAnswer()
      loading = false
    }

    if (newPage === Page.Final) {
      finishQuestionnaire()
    }

    answer = ''
    other = ''
    page = newPage
  }

  function setDisableNext(answer, other, isMultiple) {
    if (isMultiple) {
      return answer.includes(OPEN_TEXT_ANSWER) ? !other : !answer.length
    }

    return answer === OPEN_TEXT_ANSWER ? !other : !answer
  }

  $: disableNext = setDisableNext(answer, other, isMultiple)
</script>

<nav class="row v-center body-2">
  <button
    class="pagination next btn-1 row hv-center mrg-l mrg--r"
    class:disabled={disableNext}
    class:loading
    on:click={() => {
      if (page === Page.Provider)
        trackInteraction({ type: 'api_questionnaire_submit_button' })
      else trackInteraction({ type: 'api_questionnaire_next_button' })

      changePage(page + 1)
    }}
  >
    {#if page === Page.Provider}
      <Svg id="rocket-alt" w="12" />
      Submit
    {:else}
      Next
    {/if}
  </button>

  <button
    class="pagination prev btn-2 txt-center"
    class:disabled={page === Page.Do}
    on:click={() => {
      trackInteraction({ type: 'api_questionnaire_previous_button' })
      changePage(page - 1)
    }}
  >
    Previous
  </button>

  <div class="dots row v-center mrg-a mrg--l">
    {#each pages as p}
      {@const active = Number(p) === page}
      <hr class="dot" class:active />
    {/each}
  </div>
</nav>

<style lang="scss">
  nav {
    margin-top: 40px;
  }

  .pagination {
    gap: 10px;
    height: 40px;
  }

  .next {
    width: 124px;
  }

  .prev {
    --color-hover: var(--blue-hover);
    width: 101px;
  }

  .dots {
    gap: 8px;
  }

  .dot {
    border: none;
    width: 6px;
    height: 6px;
    background: var(--blue-light-3);
    border-radius: 100%;
  }

  .active {
    background: var(--blue);
  }
</style>
