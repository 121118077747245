import React from "react"
import cx from "classnames"
import toReact from "svelte-adapter/react"
import Svg from "webkit/ui/Svg/react"
import StartQuestionnaireComponent from "./Button.svelte"
import styles from "./Banner.module.scss"

const StartQuestionnaire = toReact(
  StartQuestionnaireComponent,
  {
  },
  "div"
)

const TopPanel = () => (
  <section className={cx(styles.wrapper, "row hv-center")}>
    <p className={cx(styles.text, "row v-center body-1 txt-m mrg-s mrg--r")}>
      <Svg
        id="fire-filled"
        w="11"
        h="14"
        className={cx(styles.icon, "mrg-m mrg--r")}
      />
      Get access to 3 years of historical data for 16 most popular assets!
    </p>
    <StartQuestionnaire />
  </section>
)

export default TopPanel
