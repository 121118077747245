<script context="module">
  import { dialogs } from 'webkit/ui/Dialog'
  import OnboardingDialog from './OnboardingDialog.svelte'

  export const showOnboardingDialog = (props) =>
    dialogs.showOnce(OnboardingDialog, props)
</script>

<script lang="ts">
  import { onMount } from 'svelte'
  import Dialog from 'webkit/ui/Dialog'
  import Info from './Info.svelte'
  import Intro from './Intro.svelte'
  import Final from './Final.svelte'
  import Metrics from './Metrics.svelte'
  import { Page } from './types'
  import { queryQuestionnaireUserAnswers } from '../Questionnaire/api'
  import sanpy from './sanpy.png'
  import sql from './sql.png'
  import charts from './charts.png'

  export let page: Page = Page.Intro

  const pages = {
    [Page.Library]: {
      title: 'Our library with examples',
      description:
        'You can find everything you need to know about how to access Santiment API using python here:',
      linkTitle: 'Github link to Santiment Sanpy',
      link: 'https://github.com/santiment/sanpy',
      img: sanpy,
    },
    [Page.SQL]: {
      title: 'Learn how you can use SQL for data exploration',
      description:
        'You can dig as deep into the data as you like, create custom queries that will give you the information that you need for your specific goals. ',
      linkTitle: 'Go to Santiment Queries',
      link: 'https://app.santiment.net/queries',
      img: sql,
    },
    [Page.Visual]: {
      title: 'Visual data exploration with charts',
      description:
        'On our other product Sanbase you’ll find a lot of helpful visual information: charts, dashboards, market insights and much more!',
      linkTitle: 'Go to Sanbase',
      link: 'https://app.santiment.net',
      img: charts,
    },
  }

  let answers = {}

  function setInfoPages(userAnswers = {}) {
    const arr = []

    if (userAnswers[3] === '1') {
      arr.push(pages[Page.Library])
    }

    if (userAnswers[4] === '1') {
      arr.push(pages[Page.SQL])
    }

    if (userAnswers[5] === '1') {
      arr.push(pages[Page.Visual])
    }

    return arr
  }

  $: infoPages = setInfoPages(answers)
  $: count = infoPages.length

  onMount(() => {
    queryQuestionnaireUserAnswers({ cache: false }, true).then(
      data => (answers = data)
    )
  })
</script>

<Dialog {...$$props} noTitle class="$style.dialog" let:closeDialog>
  {#if page === Page.Intro}
    <Intro {closeDialog} bind:page />
  {:else if page === Page.Metrics}
    <Metrics answer={answers[2]} {count} {closeDialog} bind:page />
  {:else if page === Page.Final}
    <Final {closeDialog} bind:page />
  {:else}
    <Info {...infoPages[page - 2]} {count} {closeDialog} bind:page />
  {/if}
</Dialog>

<style lang="scss">
  .dialog {
    max-width: 600px !important;
    background: var(--blue-light-1);
  }
</style>
