<script lang="ts">
  import Dialogs from 'webkit/ui/Dialog/Dialogs.svelte'
  import { CurrentUser$$, queryCurrentUser } from 'san-webkit/lib/stores/user'
  import { Customer$$ } from 'san-webkit/lib/stores/customer'
  import { queryCurrentUser as queryApiUser } from './Onboarding/api'
  import { normalizeAnnualDiscount } from 'san-webkit/lib/utils/subscription'

  const { currentUser$ } = CurrentUser$$(null)
  const { customer$ } = Customer$$()

  queryCurrentUser().then(data => currentUser$.set(data))

  customer$.query().then(() => {
    queryApiUser().then(user => {
      const customer = $customer$

      if (customer && user) {
        customer.isEligibleForTrial = false // user.isEligibleForApiTrial
        customer.sanBalance = 0
        customer.annualDiscount = normalizeAnnualDiscount(null)
      }

      customer$.set(customer)
    })
  })
</script>

<Dialogs />

<style>
  :global(div:where(#dialogs) section) {
    margin-bottom: unset;
  }
</style>
