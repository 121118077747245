<script>
  import { onMount } from 'svelte'
  import Svg from "webkit/ui/Svg/svelte"
  import { showOnboardingDialog } from '../Onboarding/OnboardingDialog.svelte'
  import { dataPreloader } from '../Onboarding/Intro.svelte'
  import { trackInteraction } from '../../utils/events'
  import { queryDataset } from './api'

  export let closeDialog

  let datasetUrl = ''

  function finishQuestionnaire() {
    closeDialog()
    showOnboardingDialog()
    trackInteraction({ type: 'onboarding_start_button' })
  }

  onMount(() => {
    queryDataset().then(data => datasetUrl = data)
  })
</script>

<main class="row v-center fluid">
  <section>
    <h3 class="h3 txt-center mrg-xl mrg--b">You are a 🌟</h3>

    <p class="body-2 c-fiord txt-center mrg-s mrg--b">
      Now you can access the datasets with up to 3 <br />
      years of data for 20 metrics on top of 16 of the <br />
      most popular assets! You will also <br />
      receive the link in your mailbox.
    </p>

    <a
      href={datasetUrl}
      rel="noopener noreferrer"
      target="_blank"
      data-source="questionnaire"
      data-type="api_download_data"
      class="btn-0 row hv-center body-2 fluid mrg-l mrg--b"
    >
      <Svg id="download" w="16" />
      Download the data now
    </a>

    <p class="body-3 txt-center c-waterloo">
      * Please note that the link will expire in 24 hours <br />
      for security reasons.
    </p>

    <button
      class="btn-1 txt-center body-2 action fluid"
      on:click={finishQuestionnaire}
      use:dataPreloader
    >
      Start your customised onboarding
    </button>
  </section>

  <hr class="divider" />

  <Svg illus id="san-api/banner" w="320" h="300" />
</main>

<style>
  main {
    padding: 64px 80px 80px 64px;
  }

  section {
    margin-bottom: 0;
  }

  .divider {
    height: 332px;
    border-left: 1px solid var(--porcelain);
    border-top: none;
    margin-left: 60px;
    margin-right: 80px;
  }

  a {
    padding: 4px 0;
    gap: 8px;
  }

  .action {
    height: 40px;
    margin-top: 40px;
  }
</style>
