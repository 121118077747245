import React, { useRef } from 'react'
import cx from 'classnames'
import Icon from '@santiment-network/ui/Icon'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import AccountBtn from '../AccountBtn/AccountBtn'
import SantimentProductsTooltip from '../SantimentProductsTooltip/SantimentProductsTooltip'
import mainLogo from './../../images/logos/main-logo.svg'
import { tr } from '../../utils/translate'
import styles from './Header.module.scss'

const Header = ({ isAccountPage, intl }) => {
  const toggle = useRef(null)

  const closeNav = () => {
    toggle.current.checked = false
  }

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.product}>
            <div className={styles.products}>
              <a className={styles.logo} href="/">
                <img src={mainLogo} alt="logo" />
                <div className={styles.productName}>Santiment API</div>
              </a>
              <SantimentProductsTooltip intl={intl} />
            </div>
          </div>
        </div>
        <label htmlFor="hamburger">
          <Icon type="hamburger" />
        </label>
        <input id="hamburger" type="checkbox" ref={toggle} />
        <nav className={styles.nav}>
          <label htmlFor="hamburger" className={styles.close}>
            <Icon type="close" />
          </label>
          <Link
            className={styles.link}
            to="/#features"
            data-source="navbar"
            data-type="top_features"
            onClick={closeNav}
          >
            {tr('header.use_cases', 'Features')}
          </Link>
          <Link
            className={styles.link}
            to="/#pricing"
            data-source="navbar"
            data-type="top_pricing"
            onClick={closeNav}
          >
            {tr('header.pricing', 'Pricing')}
          </Link>
          <Link
            className={styles.link}
            to="/#faq"
            data-source="navbar"
            data-type="top_faq"
            onClick={closeNav}
          >
            {tr('header.faq', 'FAQ')}
          </Link>

          <a
            className={cx(styles.external, styles.externalFirst)}
            href="https://academy.santiment.net/for-developers/"
            data-source="navbar"
            data-type="top_docs"
            rel="noopener noreferrer"
            target="_blank"
          >
            Docs
            <Icon type="external-link" className={styles.externalIcon} />
          </a>
          <div className={styles.divider} />
          <AccountBtn isAccountPage={isAccountPage} onClick={closeNav} />
        </nav>
      </div>
    </header>
  )
}
export default injectIntl(Header)
