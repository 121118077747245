import { TrackCategory } from 'webkit/analytics/events/utils'

const Interaction = TrackCategory('Interaction')

export const trackInteraction = ({ type, ...rest }) =>
  Interaction(type, { ...rest, source_url: window.location.href })


const Purchase = TrackCategory('Purchase')

export const trackPurchase = ({ type, ...rest }) =>
  Purchase(type, { ...rest, source_url: window.location.href })
