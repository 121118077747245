import React from 'react'
import cx from 'classnames'
import CheckmarkCircle from '../Pricing/CheckmarkCircle'
import styles from './Features.module.scss'

export default ({ intl, data, classes = {} }) => (
  <ul className={cx(styles.features, classes.features)}>
    {data.map((feature, i) => (
      <li key={i} className={cx(styles.feature, classes.feature)}>
        <CheckmarkCircle
          className={cx(styles.feature__icon, classes.feature__icon)}
        />
        {typeof feature === 'function' ? feature(intl) : feature}
      </li>
    ))}
  </ul>
)
