<script lang="ts">
  import Svg from 'webkit/ui/Svg/svelte'
  import Pagination from './Pagination.svelte'
  import Checkbox from './Checkbox.svelte'
  import Radio from './Radio.svelte'
  import { formatAnswer } from './utils'
  import { Page, QuestionType } from './types'
  import { OPEN_TEXT_ANSWER } from './constants'
  import { queryQuestionnaireUserAnswers } from './api'
  import { trackInteraction } from '../../utils/events'

  export let page: Page
  export let uuid: string
  export let type: QuestionType
  export let question: string
  export let answerOptions: Record<string, string> = {}
  export let hasExtraOpenTextAnswer: boolean
  export let closeDialog

  let answers = {}
  let prevAnswer = {}
  let answer = initAnswer()
  let other = ''
  let answerUuid = ''

  function initAnswer() {
    switch (type) {
      case QuestionType.MULTIPLE_SELECT: {
        return []
      }
      case QuestionType.SINGLE_SELECT:
      default: {
        return ''
      }
    }
  }

  function setUserSelection(userAnswer) {
    const userSelection = formatAnswer(userAnswer, type)

    answer = userSelection.answer
    other = userSelection.other
    prevAnswer = userSelection.prevAnswer
    answerUuid = userSelection.answerUuid
  }

  $: isMultiple = type === QuestionType.MULTIPLE_SELECT
  $: queryQuestionnaireUserAnswers({ cache: false }).then(
    data => (answers = data)
  )
  $: options = Object.entries(answerOptions)
  $: setUserSelection(answers[uuid])
  $: isOther = isMultiple
    ? answer.includes(OPEN_TEXT_ANSWER)
    : answer === OPEN_TEXT_ANSWER
  $: if (!isOther) {
    other = ''
  }
</script>

<button
  class="btn close row hv-center"
  on:click={() => {
    trackInteraction({ type: `api_close_questionnaire_q${page}_button` })
    closeDialog()
  }}
>
  <Svg id="close" w="12" />
</button>
<main class="wrapper column">
  <h4 class="h4 mrg-xl mrg--b">{question}</h4>

  <div class="column variants body-2">
    {#each options as option, i}
      {@const isLast = i === options.length - 1}
      {#if isMultiple}
        <Checkbox
          {option}
          isOther={isLast && hasExtraOpenTextAnswer}
          bind:answer
          bind:other
        />
      {:else}
        <Radio
          {option}
          isOther={isLast && hasExtraOpenTextAnswer}
          bind:answer
          bind:other
        />
      {/if}
    {/each}
  </div>

  {#if page < Page.Final}
    <Pagination
      {uuid}
      {answerUuid}
      {prevAnswer}
      {isMultiple}
      bind:answer
      bind:other
      bind:page
    />
  {/if}
</main>

<style lang="scss">
  .close {
    --fill: var(--waterloo);
    position: absolute;
    right: 24px;
    top: 24px;
    height: 12px;
  }

  .wrapper {
    width: 600px;
    padding: 64px 120px 80px;
  }

  .variants {
    gap: 12px;
  }
</style>
